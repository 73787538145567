<template>
  <div class="index-wrapper">
      <img src="https://static.smartracing.cn/images/yz-wholesaler-h5/ad.jpg" alt="">
  </div>
</template>

<script>

export default {
  name: '',
  data () {
    return {

    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
